var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2" }),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c("td", { attrs: { align: "right" } }, [
                _vm._v("Numero di pratica:"),
              ]),
              _c("td", { staticClass: "risposta" }, [
                _vm._v(_vm._s(_vm.NumeroPreventivo)),
              ]),
            ]),
            _c("tr", [
              _c("td", { attrs: { align: "right" } }, [
                _vm._v("Data di creazione:"),
              ]),
              _c("td", { staticClass: "risposta" }, [
                _vm._v(_vm._s(_vm.DataCreazione)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "col-md-2" }),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
      [
        _c("QQButton", {
          attrs: {
            label: "FINE",
            color: "blue-grey",
            icon: "mdi-check-outline",
            size: "md",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onNuovoClicked.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Acquisto del preventivo completato"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 col-md-4", attrs: { align: "justify" } },
      [
        _c("br"),
        _vm._v(
          ' La richiesta di acquisto della pratica è andata a buon fine. Sarà presa in carico al più presto per emettere le polizze richieste. Consulta la sezione "PRATICHE" per seguire lo stato di avanzamento dei lavori. '
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }